<template>
  <div class="wrapper">
    <header class="move-up">
      <div>
        <h2>Kontakt - <span>Napisz do nas</span></h2>
      </div>
    </header>
    <div class="cols">
      <base-card :columns="12" class="">
        <template #default>
          <div class="p">
            <h2>Kontakt</h2>
            <p>
              Masz pytania? Chcesz dowiedzieć się więcej o zarządzanych przez
              nas nieruchomościach? Napisz do nas korzystając z poniższego
              formularza.
            </p>
            <main-contact
              :header="false"
              :mailParams="mailParams"
            ></main-contact>
          </div>
        </template>
      </base-card>
    </div>
  </div>
</template>

<script>
import MainContact from "../components/contact/MainContact.vue";

export default {
  components: { MainContact },
  data() {
    return {
      mailParams: {
        subject: "",
        msg: "",
      },
    };
  },
  methods: {
    initParams() {
      if (this.$route.params) {
        const { subject, msg } = this.$route.params;

        this.mailParams.subject = subject;
        this.mailParams.msg = msg;
      }
    },
  },
  created() {
    this.initParams();
  },
};
</script>

<style scoped lang="scss">
@import "~@/_base.scss";

header {
  text-shadow: $multi;
  h2 {
    text-transform: uppercase;
    color: $primary;
    font-size: 1.75em;
    span {
      text-transform: capitalize;
      font-weight: normal;
      color: white;
    }
  }
}
</style>